.dashboard_box {
    height: 150px;
    width: 90%;
    border: 1px solid black;
    border-radius: 40px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.dashboard_box .text_dashboard {
    font-size: 25px;
    line-height: 40px;
}

.tabbing_section ul li a:hover {
    background-color: unset !important;
}

.tabbing_section h6 {
    margin-top: 10px;
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
}

.tabbing_section ul {
    display: flex;
    justify-content: space-between !important;
    align-items: flex-end;
    margin-top: 20px;
    margin-left: -30px;
}

.tabbing_section svg {
    display: flex;
    margin: auto;
}

.tabbing_section .nav-pills>li.active>a {
    background-color: unset;
}

.tabbing_section .nav-pills>li.active>a div svg path {
    fill: #ffffff !important;
    fill-opacity: 1;
}

.tabbing_section .nav-pills>li.active>a h6 {
    color: white;
}

.tabbing_section h6 {
    color: #ffffff62;
}

.tabbing_section .tab-content>.active {
    display: block !important;
}

.tabbing_section ul .verticle_line {
    position: relative;
}

.tabbing_section ul .verticle_line::after {
    content: '';
    height: 100%;
    width: 1px;
    border-radius: 50px;
    background-color: #ffffff20;
    position: absolute;
    top: 0px;
    right: -10px;
}

ul.showing-Location {
    display: contents;
}

.card_img .dr_img-homecare img {
    width: 67.5%;
}

.card_img .dr_img-contect-us img {
    width: 124%;
    padding-top: 21px;
}

.fix-svg-width {
    width: 25;
}

.dasinfobox h4 {
    font-size: 14px;
}

.dashboard-box-height {
    height:15rem;display: flex;
    align-items: center;
    width: 100%;
}

.modal-backdrop {
    z-index: 0;
}

.dasinfobox {
    text-align: center;
}

.align-text-center {
    text-align: center;
}

.report_list {
    background: none !important;
    padding: 0px !important;
}

.table-height-align td {
    vertical-align: inherit !important;
    line-height: normal !important;
}

.action_icon {
    /* padding: 0px 5px; */
    /* display: flex !important;
    justify-content: space-around !important; */
}

.service-provider-th {
    background-color: black;
    color: white;
    /* font-weight: ; */
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .file-input__label {
        width: 338px;
    }
}

.book_appoinment_box .doctor_details .doctor_bio p {
    margin-bottom: 3px;
}

.book_appoinment_box .doctor_details .doctor_bio .dr_name {
    margin-bottom: 4px;
}

/* .doctor_details.bg_light .dr_photo img {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50% !important;
} */
.show-doctor-no {
    color: #cad7d6 !important;
}

.upload-medical-report {}

/* .width-35{
    width: 35% !important;
} */
.profile-photo-sig {
    border: 1px solid #D8ECE8;
    width: 100%;
    height: 15rem;
    border-radius: 4px;
    background-color: #D8ECE8;
    color: rgb(0, 106, 114);
    text-align: center;    margin: 0 auto;
    cursor: pointer;
    display: flex;justify-content: center;align-items: center;
}
.profile-photo-sig p{    
    font-size: 14px;
    font-weight: 500;
}
.profile-photo-sig img{
    width: 100% ;
    height: 17rem;
    /* border-radius: 10%; */
}
.breif-details{
    min-height: 120px !important;
}
.handle-specilization{
    padding: 10px;    border-bottom: 1px solid #ddd;
    display: flex;    gap: 15px;
    justify-content: space-between;
}


.border-spe{
 background: #fff;
  
}
.availability-profile{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    gap: 7px;
}
.availability-profile select{
    
}
.documents-profile{
    
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.documents-profile select{
    width: 30%;
}
.profile-photo input{
    display: none;
    height: 20rem;
}
.profile-photo button{
    margin-top: 20px;
    margin-left: 50px;
}
.documents-profile select{
    width: 30%;
   
}
.documents-profile input{
    width: 25%;
}
.documents-profile span {
    font-size: 10px;
   
    color: #ff0000;
}

.sv_center{text-align: center;padding: 10px 0;}
.sv_center .sv_photo{width: 60px;height: 60px;margin: 0 auto;}
.sv_center .sv_photo img{ width: 100%;height: 100%;border-radius: 100%;}
.sv_center .info_text{margin-top: 20px;}
.sv_center .info_text h5 { font-size: 16px; font-weight: 500;}
.db_footer .linkbtn a{  cursor: pointer;   color: #056b66;  font-weight: 500;  font-size: 14px;}
.db_footer .linkbtn a:hover{color:#000;}
.bgwhite{background-color: #fff;}
.add_mr{margin-left: 10px; color: #056b66;}
.add_mr i{}

.mr_card{border: 1px solid #d8ece8;  background: #fff;    border-radius: 4px;    margin-bottom: 30px;display: flex;  justify-content: space-between;  padding: 20px; }
.mr_card .mr_left_text{     width: 90%;}
.mr_card .mr_left_text h5{font-size: 16px; font-weight: 500;margin-bottom: 10px;}
.mr_card .mr_download{font-size: 20px;width: 10%;text-align: center; cursor: pointer;}



@media screen and (max-width: 840px){
    .eprescription_print{
        width: 100%;
        margin: 0 auto;
    }
#capture{
        height: 100%;
        width: 794px;
        padding: 0px 0px !important;
    } 
    .eprescription_print .epres_header{    display: flex;  justify-content: space-between;padding: 10px;}
    .eprescription_print .epres_body {
        border-top: 1px solid #ddd;
    }
    .eprescription_print .epres_pate_info .pate__list h4 {font-size: 10pt !important;    width: 100%;}
    .epres_body .dr_sign {
        text-align: center;
        margin-top: 0px !important;
    }
    .eperi-t{
        display: none !important;
    }
    .eprescription_print .epres_body .ep_details {
        margin-bottom: 0px !important;
    }

}

/* #capture{
    height: 1400px;
    width: 794px;
}
#full-width-tab{
    height: 100%;
    width: 100%;
} */
span.title-semibold {
    font-weight: 500;
    padding-right: 5px;
}
.mr_card.heigth-cardaccod {
    height: 12rem;
}

.spd_card_list.width-45 p {
    text-transform: capitalize;
}
.eprescription_print{background-color: #fff;  width: 100%;
    height: auto;}
.eprescription_print .epres_header{    display: flex;  justify-content: space-between;padding: 30px;}
.eprescription_print .epres_header .epres_info_left .dr_info{margin-top: 15px;}
.eprescription_print .epres_header .epres_info_left .dr_info p{margin-top: 5px;}
.eprescription_print .epres_header .epres_info_right p{margin-top: 5px;}
.eprescription_print .epres_pate_info {padding: 30px;border-top: 1px solid #ddd;}
.eprescription_print .epres_pate_info .pate__list{display: flex;gap: 20px;    margin-bottom: 15px;}
.eprescription_print .epres_pate_info .pate__list h4{font-size: 15px;    width: 40%;}
.eprescription_print .epres_pate_info .pate__list p{    width: 60%;}
.eprescription_print .epres_body{padding: 30px;border-top: 1px solid #ddd;}
.eprescription_print .epres_body .ep_details{margin-bottom: 50px}
.eprescription_print .epres_body .ep_details h4{}
.ep_details .ep_medicine{    margin-left: 30px;    margin-top: 20px;}
.ep_details .ep_medicine p{}

.epres_body .dr_signtext{  text-align: center; margin-top: 0px;}
.epres_body .dr_sign{ text-align: center; margin-top: 50px; }



.eprescription_print .epres_footer{padding: 20px 20px;text-align: center;border-top: 1px solid #ddd;}


.spd_card {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}
.spd_card .spd_card_list{border: 1px solid #ddd;text-align: center;padding: 20px 10px;    width: 20%;    border-radius: 4px;}
.spd_card .spd_card_list h4{margin-top: 10px;}
.mh51{height: 51px;}
.spd_card .spd_card_list2{border: 1px solid #ddd;text-align: center;padding: 20px 10px;    width: 25%;    border-radius: 4px;}
.spd_card_list2 p{
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-weight: 500;

}
.spd_card_list2 .btn-info {
    background-color: #056B66;
    border-color: #056B66;
}
.spd_card_list2 .btn-info:hover {
    background-color: #Fff;
    border-color: #056B66;
    color: #056B66; ;
}


.btnreject {  
    color: #056b66;  
    font-size: 14px;font-weight: 500;
}
.sidebar_menu .header{background: #fff;    text-align: center;    padding: 5px 10px;}
.sidebar-postion-fixed{position: fixed;left: 0;top: 70px;bottom: 0;}
.button_set{position: absolute;left: 0;bottom: 0;right: 0;border-radius: 0;padding: 15px 10px; font-size: 16px;     margin-bottom: 10px;}

.table-bordered a{cursor: pointer;}

.size_validation{   
   
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #ff0000;
    padding-left: 250px;
    margin-top: -8px;
}

.profile-upload{
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-top: 7px;
}
.profile-upload span{
    background: #056b66;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
    display: block;
    
}
.profile-upload-sign{
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;
}
.profile-upload-sign span{
    background: #056b66;
    padding: 5px;
    color: #fff;
    border-radius: 5px;
    display: block;
    
}
.book-ambulance-img-fixed-width{
    width: 100%;
    height: 110px;
    
}
.btnhours-selected{
    color: #056B66 !important;
    border: 1px solid #056B66;
    background: transparent;
}